// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-fr-js": () => import("./../../../src/pages/contact.fr.js" /* webpackChunkName: "component---src-pages-contact-fr-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-nl-js": () => import("./../../../src/pages/contact.nl.js" /* webpackChunkName: "component---src-pages-contact-nl-js" */),
  "component---src-pages-contact-ru-js": () => import("./../../../src/pages/contact.ru.js" /* webpackChunkName: "component---src-pages-contact-ru-js" */),
  "component---src-pages-fees-en-js": () => import("./../../../src/pages/fees.en.js" /* webpackChunkName: "component---src-pages-fees-en-js" */),
  "component---src-pages-fees-fr-js": () => import("./../../../src/pages/fees.fr.js" /* webpackChunkName: "component---src-pages-fees-fr-js" */),
  "component---src-pages-fees-js": () => import("./../../../src/pages/fees.js" /* webpackChunkName: "component---src-pages-fees-js" */),
  "component---src-pages-fees-nl-js": () => import("./../../../src/pages/fees.nl.js" /* webpackChunkName: "component---src-pages-fees-nl-js" */),
  "component---src-pages-fees-ru-js": () => import("./../../../src/pages/fees.ru.js" /* webpackChunkName: "component---src-pages-fees-ru-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-nl-js": () => import("./../../../src/pages/index.nl.js" /* webpackChunkName: "component---src-pages-index-nl-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-info-nl-js": () => import("./../../../src/pages/info.nl.js" /* webpackChunkName: "component---src-pages-info-nl-js" */),
  "component---src-pages-legal-general-terms-and-conditions-en-js": () => import("./../../../src/pages/legal/general-terms-and-conditions.en.js" /* webpackChunkName: "component---src-pages-legal-general-terms-and-conditions-en-js" */),
  "component---src-pages-legal-general-terms-and-conditions-fr-js": () => import("./../../../src/pages/legal/general-terms-and-conditions.fr.js" /* webpackChunkName: "component---src-pages-legal-general-terms-and-conditions-fr-js" */),
  "component---src-pages-legal-general-terms-and-conditions-js": () => import("./../../../src/pages/legal/general-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-general-terms-and-conditions-js" */),
  "component---src-pages-legal-general-terms-and-conditions-nl-js": () => import("./../../../src/pages/legal/general-terms-and-conditions.nl.js" /* webpackChunkName: "component---src-pages-legal-general-terms-and-conditions-nl-js" */),
  "component---src-pages-legal-general-terms-and-conditions-ru-js": () => import("./../../../src/pages/legal/general-terms-and-conditions.ru.js" /* webpackChunkName: "component---src-pages-legal-general-terms-and-conditions-ru-js" */),
  "component---src-pages-legal-privacy-statement-en-js": () => import("./../../../src/pages/legal/privacy-statement.en.js" /* webpackChunkName: "component---src-pages-legal-privacy-statement-en-js" */),
  "component---src-pages-legal-privacy-statement-fr-js": () => import("./../../../src/pages/legal/privacy-statement.fr.js" /* webpackChunkName: "component---src-pages-legal-privacy-statement-fr-js" */),
  "component---src-pages-legal-privacy-statement-js": () => import("./../../../src/pages/legal/privacy-statement.js" /* webpackChunkName: "component---src-pages-legal-privacy-statement-js" */),
  "component---src-pages-legal-privacy-statement-nl-js": () => import("./../../../src/pages/legal/privacy-statement.nl.js" /* webpackChunkName: "component---src-pages-legal-privacy-statement-nl-js" */),
  "component---src-pages-legal-privacy-statement-ru-js": () => import("./../../../src/pages/legal/privacy-statement.ru.js" /* webpackChunkName: "component---src-pages-legal-privacy-statement-ru-js" */),
  "component---src-pages-translators-en-js": () => import("./../../../src/pages/translators.en.js" /* webpackChunkName: "component---src-pages-translators-en-js" */),
  "component---src-pages-translators-fr-js": () => import("./../../../src/pages/translators.fr.js" /* webpackChunkName: "component---src-pages-translators-fr-js" */),
  "component---src-pages-translators-js": () => import("./../../../src/pages/translators.js" /* webpackChunkName: "component---src-pages-translators-js" */),
  "component---src-pages-translators-nl-js": () => import("./../../../src/pages/translators.nl.js" /* webpackChunkName: "component---src-pages-translators-nl-js" */),
  "component---src-pages-translators-ru-js": () => import("./../../../src/pages/translators.ru.js" /* webpackChunkName: "component---src-pages-translators-ru-js" */),
  "component---src-pages-useful-links-en-js": () => import("./../../../src/pages/useful-links.en.js" /* webpackChunkName: "component---src-pages-useful-links-en-js" */),
  "component---src-pages-useful-links-fr-js": () => import("./../../../src/pages/useful-links.fr.js" /* webpackChunkName: "component---src-pages-useful-links-fr-js" */),
  "component---src-pages-useful-links-js": () => import("./../../../src/pages/useful-links.js" /* webpackChunkName: "component---src-pages-useful-links-js" */),
  "component---src-pages-useful-links-nl-js": () => import("./../../../src/pages/useful-links.nl.js" /* webpackChunkName: "component---src-pages-useful-links-nl-js" */),
  "component---src-pages-useful-links-ru-js": () => import("./../../../src/pages/useful-links.ru.js" /* webpackChunkName: "component---src-pages-useful-links-ru-js" */)
}

